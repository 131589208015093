<template>
  <div
    v-if="$store.state.fastPermissoes.ativo == 'S' && ($store.state.fastPermissoes.administrador == 'S') "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Suporte</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Suporte do
                  <span>FastEAD</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <!-- fd-app-content -->
              <section>
                <tabs :options="{ useUrlFragment: false }">
                  <tab
                    class="pt-0"
                    prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                    name="Atendimento da plataforma"
                  >
                    <section class="fd-app-meus-cursos mt-0">
                      <div class="fd-app-meus-cursos-list">
                        <div
                          id="div-atendimento-modoead"
                          class="row"
                        >
                          <!-- fd-app-atendimento -->
                          <section
                            class="fd-app-atendimento mt-0"
                            style="overflow: auto"
                          >
                            <div
                              v-if="fastAtendimentoAdmin.id_plataforma"
                              class="fd-app-atendimento-contato"
                            >
                              <div class="table-responsive">
                                <table class="table table-sm fd-app-atendimento-contato-table">
                                  <thead>
                                    <tr>
                                      <th>Assunto</th>
                                      <th class="aluno_font_color">
                                        Contato
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-if="fastAtendimentoAdmin.email_suporte">
                                      <td>
                                        <img
                                          v-lazy="require('@/assets/images/app/table-icon-comercial.png')"
                                        >
                                        <span>Suporte</span>
                                      </td>
                                      <td class="aluno_font_color">
                                        {{ fastAtendimentoAdmin.email_suporte }}
                                        <br>
                                        <a
                                          v-if="fastAtendimentoAdmin.telefone_suporte"
                                          class="btn btn-hollow-phone btn-lg aluno_font_color"
                                          href="#"
                                        >
                                          <b-icon-telephone-inbound />
                                          {{ fastAtendimentoAdmin.telefone_suporte }}
                                        </a>
                                      </td>
                                    </tr>

                                    <tr v-if="fastAtendimentoAdmin.email_financeiro">
                                      <td>
                                        <img
                                          v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                        >
                                        <span>Financeiro</span>
                                      </td>
                                      <td class="aluno_font_color">
                                        {{ fastAtendimentoAdmin.email_financeiro }}
                                        <br>
                                        <a
                                          v-if="fastAtendimentoAdmin.telefone_financeiro"
                                          class="btn btn-hollow-phone btn-lg aluno_font_color"
                                          href="#"
                                        >
                                          <b-icon-telephone-inbound />
                                          {{ fastAtendimentoAdmin.telefone_financeiro }}
                                        </a>
                                      </td>
                                    </tr>

                                    <tr v-if="fastAtendimentoAdmin.email_pedagogico">
                                      <td>
                                        <img
                                          v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                        >
                                        <span>Pedagógico</span>
                                      </td>
                                      <td class="aluno_font_color">
                                        {{ fastAtendimentoAdmin.email_pedagogico }}
                                        <br>
                                        <a
                                          v-if="fastAtendimentoAdmin.telefone_pedagogico"
                                          class="btn btn-hollow-phone btn-lg aluno_font_color"
                                          href="#"
                                        >
                                          <b-icon-telephone-inbound />
                                          {{ fastAtendimentoAdmin.telefone_pedagogico }}
                                        </a>
                                      </td>
                                    </tr>

                                    <tr v-if="fastAtendimentoAdmin.email_secretaria">
                                      <td>
                                        <img
                                          v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                        >
                                        <span>Secretaria</span>
                                      </td>
                                      <td class="aluno_font_color">
                                        {{ fastAtendimentoAdmin.email_secretaria }}
                                        <br>
                                        <a
                                          v-if="fastAtendimentoAdmin.telefone_secretaria"
                                          class="btn btn-hollow-phone btn-lg aluno_font_color"
                                          href="#"
                                        >
                                          <b-icon-telephone-inbound />
                                          {{ fastAtendimentoAdmin.telefone_secretaria }}
                                        </a>
                                      </td>
                                    </tr>
                                    <tr v-if="fastAtendimentoAdmin.email_atendimento">
                                      <td>
                                        <img
                                          v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                          class="fast-menu-lives-aluno-fast"
                                        >
                                        <span class="fast-menu-lives-aluno-fast">Atendimento</span>
                                      </td>
                                      <td
                                        class="aluno_font_color"
                                      >
                                        {{ fastAtendimentoAdmin.email_atendimento }}
                                      </td>
                                    </tr>
                                    <tr v-if="fastAtendimentoAdmin.email_atendimento_professor">
                                      <td>
                                        <img
                                          v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                        >
                                        <span>Professor</span>
                                      </td>
                                      <td
                                        class="aluno_font_color"
                                      >
                                        {{ fastAtendimentoAdmin.email_atendimento_professor }}
                                      </td>
                                    </tr>
                                    <tr v-if="fastAtendimentoAdmin.email_atendimento_rh">
                                      <td>
                                        <img
                                          v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                        >
                                        <span>RH</span>
                                      </td>
                                      <td
                                        class="aluno_font_color"
                                      >
                                        {{ fastAtendimentoAdmin.email_atendimento_rh }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="fd-app-atendimento-contato-list">
                                <a
                                  v-if="fastAtendimentoAdmin.chat_on_line"
                                  id="atendChatOnline"
                                  class="btn btn-chat-online btn-lg"
                                  href="#"
                                >
                                  <b-icon-chat-quote />
                                  {{ fastAtendimentoAdmin.chat_on_line }}
                                </a>
                                <a
                                  v-if="fastAtendimentoAdmin.whatsapp"
                                  id="atendWhats"
                                  class="btn btn-hollow-whatsapp btn-lg"
                                  target="_blank"
                                  :href="'https://api.whatsapp.com/send?phone=+' + fastAtendimentoAdmin.whatsapp.replace('(', '').replace(')', '').replace(' ', '') + '&amp;text='"
                                >
                                  <b-icon-chat-quote />
                                  {{ fastAtendimentoAdmin.whatsapp }}
                                </a>
                                <a
                                  v-if="fastAtendimentoAdmin.telefone"
                                  id="atendTelefone"
                                  class="btn btn-hollow-phone btn-lg"
                                  href="#"
                                >
                                  <b-icon-telephone-inbound />
                                  {{ fastAtendimentoAdmin.telefone }}
                                </a>
                              </div>
                            </div>
                          </section>
                          <!-- /fd-app-atendimento -->
                        </div>
                      </div>
                    </section>
                  </tab>
                  <tab
                    class="pt-0"
                    prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                    name="Chamados"
                  >
                    <section class="fd-app-content">
                      <div class="row mt-4">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                              <h4
                                class="aluno_font_color"
                              >
                                Lista de chamados ({{ fastSuporteFiltro.length }})
                              </h4>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click="showModal('modalChamado')"
                              >
                                <small>+ Abrir chamado</small>
                              </button>
                            </div>
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th />
                                    <th>
                                      <small class="font-weight-bold">Usuário</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Plataforma</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Assunto</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Texto</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Data</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Resolvido</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Respondido</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Prioridade</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastSuporteFiltro.length">
                                  <tr
                                    v-for="(chamado, index) in pageOfFastSuporte"
                                    :key="index"
                                  >
                                    <td>
                                      <small>{{ index + 1 }}</small>
                                    </td>
                                    <td>
                                      <small>{{ chamado.nome_usuario }}</small>
                                    </td>
                                    <td>
                                      <small>{{ chamado.nome_plataforma }}</small>
                                    </td>
                                    <td>
                                      <small>{{ chamado.tipo == 'P' ? 'Problemas' : 'Sugestões' }}</small>
                                    </td>
                                    <td>
                                      <small>{{ removeHTMLpergunta(chamado.texto, 80) }}</small>
                                    </td>
                                    <td>
                                      <small>{{ new Date(chamado.data_hora).toLocaleDateString() }}</small>
                                    </td>
                                    <td class="text-center">
                                      <small
                                        v-if="chamado.resolvido == 'S'"
                                        class="badge badge-success"
                                      >sim</small>
                                      <small
                                        v-else
                                        class="badge badge-danger"
                                      >não</small>
                                    </td>
                                    <td class="text-center">
                                      <small
                                        v-if="chamado.id_resposta"
                                        class="badge badge-success"
                                      >sim</small>
                                      <small
                                        v-else
                                        class="badge badge-danger"
                                      >não</small>
                                    </td>
                                    <td class="text-center">
                                      <small
                                        v-if="chamado.prioridade == 0"
                                        class="badge badge-danger"
                                      >Crítica</small>
                                      <small
                                        v-else-if="chamado.prioridade == 1"
                                        class="badge badge-warning"
                                      >Alta</small>
                                      <small
                                        v-else-if="chamado.prioridade == 2"
                                        class="badge badge-info"
                                      >Média</small>
                                      <small
                                        v-else-if="chamado.prioridade == 3"
                                        class="badge badge-success"
                                      >Baixa</small>
                                    </td>

                                    <td class="text-center">
                                      <button
                                        class="btn btn-sm btn-success ml-1 mr-1 mb-1"
                                        @click="exibeModalChamadoEditar(chamado)"
                                      >
                                        <small>Visualizar</small>
                                      </button>
                                      <button
                                        v-if="chamado.resolvido != 'S'"
                                        class="btn btn-sm btn-primary ml-1 mr-1 mb-1"
                                        @click="exibeModalChamadoFinalizar(chamado)"
                                      >
                                        <small>Finalizar</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-danger ml-1 mr-1 mb-1"
                                        @click="exibeModalChamadoExcluir(chamado)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr class="text-center">
                                    <td colspan="10">
                                      Nenhum chamado encontrado
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastSuporteFiltro"
                                @changePage="pageOfFastSuporte = $event"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </tab>
                </tabs>
              </section>

              <!-- /fd-app-content -->
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalChamado"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
      :shift-y="0.1"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Abrir chamado</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalChamado')"
              >
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div class="col-12 mt-4">
              <h6>Chamado</h6>
            </div>
            <div class="col-12">
              <quill-editor
                v-model="fastSuporteNovo.texto"
                :options="editorOption"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
              <div class="row">
                <div class="col-12">
                  <h6>Assunto</h6>
                </div>
                <div class="col-12">
                  <select
                    v-model="fastSuporteNovo.tipo"
                    class="form-control"
                  >
                    <option value="P">
                      Problemas
                    </option>
                    <option value="S">
                      Sugestões
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
              <div class="row">
                <div class="col-12">
                  <h6>Prioridade</h6>
                </div>
                <div class="col-12">
                  <select
                    v-model="fastSuporteNovo.prioridade"
                    class="form-control"
                  >
                    <option value="0">
                      Crítica
                    </option>
                    <option value="1">
                      Alta
                    </option>
                    <option value="2">
                      Média
                    </option>
                    <option value="3">
                      Baixa
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <h6>Enviar anexos:</h6>
            </div>
            <div class="col-12">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccess"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p>Arraste um arquivo aqui ou</p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
            <div class="col-12 mt-4 text-center">
              <button
                class="btn btn-primary"
                @click="insereFastPlataformaSuporte()"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalChamadoEditar"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
      :shift-y="0.1"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Chamado aberto por {{ fastSuporteEditar.nome_usuario }}</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalChamadoEditar')"
              >
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div
              v-if="fastSuporteEditar.data_hora"
              class="col-12 mt-4"
            >
              <h6>Chamado - {{ new Date(fastSuporteEditar.data_hora).toLocaleDateString() }} às {{ fastSuporteEditar.data_hora.split('T').pop().split('.')[0] }}</h6>
            </div>
            <div class="col-12">
              <quill-editor
                v-model="fastSuporteEditar.texto"
                :options="editorOptionEdit"
                :disabled="true"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
              <div class="row">
                <div class="col-12">
                  <h6>Assunto</h6>
                </div>
                <div class="col-12">
                  <select
                    v-model="fastSuporteEditar.tipo"
                    class="form-control"
                    :readonly="true"
                    :disabled="true"
                  >
                    <option value="P">
                      Problemas
                    </option>
                    <option value="S">
                      Sugestões
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
              <div class="row">
                <div class="col-12">
                  <h6>Prioridade</h6>
                </div>
                <div class="col-12">
                  <select
                    v-model="fastSuporteEditar.prioridade"
                    class="form-control"
                    :readonly="true"
                    :disabled="true"
                  >
                    <option value="0">
                      Crítica
                    </option>
                    <option value="1">
                      Alta
                    </option>
                    <option value="2">
                      Média
                    </option>
                    <option value="3">
                      Baixa
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <h6>Anexos:</h6>
            </div>
            <div
              v-if="fastSuporteEditar.anexos"
              class="col-12"
            >
              <ul
                v-if="fastSuporteEditar.anexos[0].path_suporte_anexo"
                class="list-group"
              >
                <li
                  v-for="(anexo, index) in fastSuporteEditar.anexos"
                  :key="index"
                  class="list-group-item"
                >
                  <a
                    :href="anexo.path_suporte_anexo"
                    target="_blank"
                  >{{ anexo.path_suporte_anexo }}</a>
                </li>
              </ul>
              <ul
                v-else
                class="list-group"
              >
                <li class="list-group-item">
                  Nenhum arquivo anexado
                </li>
              </ul>
            </div>

            <div
              v-if="fastSuporteEditar.respostas"
              class="col-12 p-4"
            >
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 p-2">
                  <h6>Respostas:</h6>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 p-2 text-right">
                  <button
                    v-if="fastSuporteEditar.resolvido != 'S'"
                    class="btn btn-sm btn-success ml-1 mr-1 mb-1"
                    @click="exibeModalChamadoResponder(fastSuporteEditar)"
                  >
                    <small>Responder</small>
                  </button>
                </div>
                <div
                  v-if="fastSuporteEditar.respostas"
                  class="col-12 p-2"
                >
                  <div class="row">
                    <div
                      v-for="(r, index) in fastSuporteEditar.respostas"
                      :key="index"
                      class="col-12 mb-4 shadow p-3 bg-white rounded"
                    >
                      <small v-if="r.nome_usuario_resposta">
                        Enviado em {{ new Date(r.data_hora_resposta).toLocaleDateString() }} às {{ r.data_hora_resposta.split('T').pop().split('.')[0] }} por
                        <b>{{ r.nome_usuario_resposta }}</b>
                      </small>
                      <small
                        v-else
                      >Enviado em {{ new Date(r.data_hora_resposta).toLocaleDateString() }} às {{ r.data_hora_resposta.split('T').pop().split('.')[0] }}</small>

                      <quill-editor
                        :content="r.texto_resposta"
                        :options="editorOptionEdit"
                        :disabled="true"
                      />
                      <div class="col-12 mt-2">
                        <small>
                          <b>Anexos:</b>
                        </small>
                        <div v-if="r.anexos_respostas">
                          <ul
                            v-if="r.anexos_respostas[0].path_resposta_anexo"
                            class="list-group"
                          >
                            <li
                              v-for="(ar, index) in r.anexos_respostas"
                              :key="index"
                              class="list-group-item"
                            >
                              <a
                                :href="ar.path_resposta_anexo"
                                target="_blank"
                              >
                                <small>{{ ar.path_resposta_anexo }}</small>
                              </a>
                            </li>
                          </ul>
                          <ul
                            v-else
                            class="list-group"
                          >
                            <li class="list-group-item">
                              <small>Nenhum arquivo anexado</small>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="col-12 text-left"
                >
                  <h6 class="text-warning">
                    Aguardando resposta...
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalChamadoResponder"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
      :shift-y="0.1"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-6">
              <h4>Responder chamado</h4>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalChamadoResponder')"
              >
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div class="col-12 mt-4">
              <h6>Chamado</h6>
            </div>
            <div class="col-12">
              <quill-editor
                v-model="fastSuporteResposta.texto"
                :options="editorOption"
              />
            </div>

            <div class="col-12 mt-4">
              <h6>Enviar anexos:</h6>
            </div>
            <div class="col-12">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessResposta"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p>Arraste um arquivo aqui ou</p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
              <button
                class="btn btn-primary mr-1 ml-1"
                @click="insereFastPlataformaSuporteResposta()"
              >
                Responder
              </button>
              <button
                class="btn btn-danger mr-1 ml-1"
                @click="showModal('modalChamadoEditar', 'modalChamadoResponder')"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalChamadoExcluir"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
      :shift-y="0.1"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-8">
              <h4>Excluir chamado?</h4>
            </div>
            <div class="col-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalChamadoExcluir')"
              >
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div class="col-12 mt-4 text-left">
              <button
                class="btn btn-danger"
                @click="excluirFastPlataformaSuporte()"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalChamadoFinalizar"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
      :shift-y="0.1"
    >
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row pl-4 pr-4">
            <div class="col-8">
              <h4>Marcar chamado como resolvido?</h4>
            </div>
            <div class="col-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalChamadoFinalizar')"
              >
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 pr-lg-4 pb-lg-0 pl-lg-4">
          <div class="row pr-lg-4 pl-lg-4 pb-4">
            <div class="col-12 mt-4 text-left">
              <button
                class="btn btn-danger"
                @click="finalizarFastPlataformaSuporte()"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoAdminSuporte",
  components: {
    quillEditor,
    Pagination,
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastSuporteNovo: {
        id_suporte: "",
        id_usuario: "",
        id_usuario_resolvido: "",
        id_plataforma: this.$route.params.id_plataforma,
        texto: "",
        data_hora: "",
        resolvido: "",
        tipo: "P",
        prioridade: 3,
        paths: [],
      },
      fastSuporteEditar: {
        id_suporte: "",
        id_usuario: "",
        id_usuario_resolvido: "",
        id_plataforma: this.$route.params.id_plataforma,
        texto: "",
        data_hora: "",
        resolvido: "",
        tipo: "P",
        prioridade: 3,
        paths: [],
      },
      fastSuporteResposta: {
        id_resposta: "",
        id_suporte: "",
        id_usuario: "",
        data_hora: "",
        texto: "",
        paths: [],
      },
      fastSuporteExcluir: {
        id_suporte: "",
        id_usuario: "",
        id_usuario_resolvido: "",
        id_plataforma: this.$route.params.id_plataforma,
        texto: "",
        data_hora: "",
        resolvido: "",
        tipo: "P",
        prioridade: 3,
        paths: [],
      },
      fastSuporteFinalizar: {
        id_suporte: "",
        id_usuario: "",
        id_usuario_resolvido: "",
        id_plataforma: this.$route.params.id_plataforma,
        texto: "",
        data_hora: "",
        resolvido: "",
        tipo: "P",
        prioridade: 3,
        paths: [],
      },
      fastSuporte: [],
      fastSuporteFiltro: [],
      pageOfFastSuporte: [],
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
      editorOptionEdit: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
      },
      // Upload arquivo
      optionsArquivo: {
        target: `${settings.endApiFastEad}api/fast_plataforma_suporte/upload`,
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: false,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
            "rar",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Atendimento
      fastAtendimentoAdmin: {
        chat_on_line: "",
        email_atendimento: "",
        email_atendimento_professor: "",
        email_atendimento_rh: "",
        email_financeiro: "",
        email_pedagogico: "",
        email_secretaria: "",
        email_suporte: "",
        telefone: "",
        telefone_financeiro: "",
        telefone_pedagogico: "",
        telefone_secretaria: "",
        telefone_suporte: "",
      },
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getPlataformaSuporte();
          this.getFastAtendimentoAdmin()
            .then((e) => {
              if (e) this.fastAtendimentoAdmin = e;

              console.log(
                "this.fastAtendimentoAdmin",
                this.fastAtendimentoAdmin
              );
            })
            .catch((e) => {
              console.log("Sem informações de atendimento", e);
            });
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getPlataformaSuporte() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_suporte/lista?id_suporte=0&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();

        const obj = Array.from(json);
        if (obj.length > 0) {
          // Chamados únicos
          const chamados = Array.from(
            new Set(obj.map((a) => a.id_suporte))
          ).map((id_suporte) => {
            return obj.find((a) => a.id_suporte === id_suporte);
          });

          // Separa anexos do chamado
          chamados.forEach((f, index) => {
            if (!chamados[index].anexos) chamados[index].anexos = [];
            obj.forEach((u, index2) => {
              if (
                obj[index2].id_suporte == chamados[index].id_suporte &&
                obj[index2].id_suporte_anexo
              ) {
                f.anexos.push(obj[index2]);
              }
            });
          });
          // Elimina anexos do chamado repetidos
          chamados.forEach((f, index) => {
            const anexos_chamado = Array.from(
              new Set(chamados[index].anexos.map((a) => a.id_suporte_anexo))
            ).map((id_suporte_anexo) => {
              return chamados[index].anexos.find(
                (a) => a.id_suporte_anexo === id_suporte_anexo
              );
            });
            /*
            anexos_chamado.map(function (item) {
              delete item.data_hora;
              delete item.data_hora_resposta;
              delete item.email;
              delete item.id_plataforma;
              delete item.id_resposta;
              delete item.id_resposta_anexo;
              delete item.id_suporte;
              delete item.id_usuario;
              delete item.id_usuario_resolvido;
              delete item.id_usuario_resposta;
              delete item.nome_plataforma;
              delete item.nome_usuario;
              delete item.path_resposta_anexo;
              delete item.prioridade;
              delete item.resolvido;
              delete item.texto;
              delete item.texto_resposta;
              delete item.tipo;
              return item;
            });*/
            chamados[index].anexos = anexos_chamado;
          });

          // Separa respostas do chamado
          chamados.forEach((f, index) => {
            if (!f.respostas) f.respostas = [];
            const respostas = [];
            obj.forEach((u, index2) => {
              if (
                obj[index2].id_suporte == chamados[index].id_suporte &&
                obj[index2].id_resposta
              ) {
                respostas.push(obj[index2]);
              }
            });
            const respostas_unicas = Array.from(
              new Set(respostas.map((a) => a.id_resposta))
            ).map((id_resposta) => {
              return respostas.find((a) => a.id_resposta === id_resposta);
            });
            chamados[index].respostas = respostas_unicas;
          });

          // Separa anexos das respostas do chamado
          chamados.forEach((f, index) => {
            if (f.respostas) {
              f.respostas.forEach((r) => {
                if (!r.anexos_respostas) r.anexos_respostas = [];
                obj.forEach((u, index) => {
                  if (u.id_resposta == r.id_resposta && r.id_resposta_anexo)
                    r.anexos_respostas.push(u);
                });
                const anexos_respostas = Array.from(
                  new Set(r.anexos_respostas.map((a) => a.id_resposta_anexo))
                ).map((id_resposta_anexo) => {
                  return r.anexos_respostas.find(
                    (a) => a.id_resposta_anexo === id_resposta_anexo
                  );
                });

                r.anexos_respostas = anexos_respostas;
              });
            }
          });

          //this.fastSuporte = chamados;
          this.fastSuporteFiltro = chamados;

          console.log("this.fastSuporteFiltro", this.fastSuporteFiltro);
        } else {
          this.fastSuporte = [];
          this.fastSuporteFiltro = [];
        }
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    fileValidation(e) {
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.fastSuporteNovo.paths.push(result.url);
      }
    },
    fileSuccessResposta(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        this.fastSuporteResposta.paths.push(result.url);
      }
    },
    async insereFastPlataformaSuporte() {
      this.$store.state.fastCarregando = true;
      if (!this.fastSuporteNovo.texto)
        this.fastSuporteNovo.texto = "Sem detalhes";

      let modelSuporte = {
        fast_plataforma_suporte: {
          id_plataforma: this.fastSuporteNovo.id_plataforma,
          texto: this.fastSuporteNovo.texto,
          tipo: this.fastSuporteNovo.tipo,
          prioridade: this.fastSuporteNovo.prioridade,
        },
        paths: this.fastSuporteNovo.paths.join(),
      };

      console.log("modelSuporte", modelSuporte);

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_suporte/insere",
          this.fastAjaxOptions("POST", JSON.stringify(modelSuporte))
        );
        let obj = await resp.json();
        console.log(obj);
        this.$toast.open({
          message: "Chamado enviado com sucesso",
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        this.fastSuporteNovo = {
          id_suporte: "",
          id_usuario: "",
          id_usuario_resolvido: "",
          id_plataforma: this.$route.params.id_plataforma,
          texto: "",
          data_hora: "",
          resolvido: "",
          tipo: "P",
          prioridade: 3,
          paths: [],
        };
        this.hideModal("modalChamado");
        this.getPlataformaSuporte();
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    removeHTMLpergunta(str, limite) {
      let strFormated = "";
      if (str) {
        strFormated = str.replace(/<[^>]+>/g, "");
        if (strFormated.length > 10)
          strFormated = strFormated.substring(0, limite) + "...";
      }
      if (!strFormated.length) strFormated = "";
      return strFormated;
    },
    exibeModalChamadoEditar(chamado) {
      if (chamado.respostas) {
        if (chamado.respostas.length) {
          const ids_usuarios = [];

          chamado.respostas.map((u) => {
            if (u.id_usuario_resposta) ids_usuarios.push(u.id_usuario_resposta);
          });
          console.log("ids_usuarios", ids_usuarios);
          if (ids_usuarios.length) {
            this.getUsuariosIds(ids_usuarios.join()).then((e) => {
              chamado.respostas.forEach((u) => {
                e.forEach((r) => {
                  if (u.id_usuario_resposta == r.id_usuario) {
                    u.image_usuario_resposta = r.image;
                    u.nome_usuario_resposta = r.nome_usuario;
                  }
                });
              });
              this.fastSuporteEditar = chamado;
              this.showModal("modalChamadoEditar");
            });
          } else {
            this.fastSuporteEditar = chamado;
            this.showModal("modalChamadoEditar");
          }
        } else {
          this.fastSuporteEditar = chamado;
          this.showModal("modalChamadoEditar");
        }
      } else {
        this.fastSuporteEditar = chamado;
        this.showModal("modalChamadoEditar");
      }
    },
    exibeModalChamadoResponder(chamado) {
      this.fastSuporteResposta.id_suporte = chamado.id_suporte;
      this.hideModal("modalChamadoEditar");
      this.showModal("modalChamadoResponder");
    },
    exibeModalChamadoExcluir(chamado) {
      this.fastSuporteExcluir = chamado;
      this.showModal("modalChamadoExcluir");
    },
    exibeModalChamadoFinalizar(chamado) {
      this.fastSuporteFinalizar = chamado;
      this.showModal("modalChamadoFinalizar");
    },
    async insereFastPlataformaSuporteResposta() {
      this.$store.state.fastCarregando = true;
      if (!this.fastSuporteResposta.texto)
        this.fastSuporteResposta.texto = "Sem detalhes";

      let modelSuporteResposta = {
        fast_plataforma_suporte_resposta: {
          id_suporte: this.fastSuporteResposta.id_suporte,
          texto: this.fastSuporteResposta.texto,
        },
        paths: this.fastSuporteResposta.paths.join(),
      };

      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_suporte/insere_resposta",
          this.fastAjaxOptions("POST", JSON.stringify(modelSuporteResposta))
        );
        let obj = await resp.json();
        //console.log(obj);
        this.$toast.open({
          message: "Chamado respondido com sucesso",
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        this.fastSuporteResposta = {
          id_resposta: "",
          id_suporte: "",
          id_usuario: "",
          data_hora: "",
          texto: "",
          paths: [],
        };
        this.hideModal("modalChamadoResponder");
        this.getPlataformaSuporte();
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },

    async excluirFastPlataformaSuporte() {
      const fast_plataforma_suporte = {
        id_suporte: this.fastSuporteExcluir.id_suporte,
        id_plataforma: this.$route.params.id_plataforma,
      };

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_suporte/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_suporte))
        );
        let obj = await resp.json();
        console.log("excluirFastPlataformaSuporte", obj);
        this.$toast.open({
          message: "Chamado excluído com sucesso",
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        this.fastSuporteExcluir = {
          id_suporte: "",
          id_usuario: "",
          id_usuario_resolvido: "",
          id_plataforma: this.$route.params.id_plataforma,
          texto: "",
          data_hora: "",
          resolvido: "",
          tipo: "P",
          prioridade: 3,
          paths: [],
        };
        this.hideModal("modalChamadoExcluir");
        this.getPlataformaSuporte();
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    async finalizarFastPlataformaSuporte() {
      const fast_plataforma_suporte = {
        id_suporte: this.fastSuporteFinalizar.id_suporte,
        id_plataforma: this.$route.params.id_plataforma,
        resolvido: "S",
      };

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_suporte/atualiza",
          this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_suporte))
        );
        let obj = await resp.json();
        this.$toast.open({
          message: "Chamado atualizado com sucesso",
          type: "success",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        this.fastSuporteFinalizar = {
          id_suporte: "",
          id_usuario: "",
          id_usuario_resolvido: "",
          id_plataforma: this.$route.params.id_plataforma,
          texto: "",
          data_hora: "",
          resolvido: "",
          tipo: "P",
          prioridade: 3,
          paths: [],
        };
        this.hideModal("modalChamadoFinalizar");
        this.getPlataformaSuporte();
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    async getUsuariosIds(ids_usuarios) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_usuario/lista_ids?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&ids_usuario=" +
              ids_usuarios,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = Array.from(json);
          resolve(obj);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Atendimento
    async getFastAtendimentoAdmin() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_personalizacao/lista?id_plataforma=1287",
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();

          resolve(json[0]);
        } catch (e) {
          console.log("Erro", e);
          reject(e);
        }
      });
    },
  },
};
</script>

<style scope>
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
    padding-top: 0;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
